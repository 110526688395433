<template>
    <div class="home">
        <div class="body_wrapper">
            <header class="header_area">
                <div class="main-navigation shadow-sm">
                    <div class="container-fluid h-100 px-lg-5">
                        <div class="main-navigation--inner">
                            <a href="/">
                                <img src="https://dev-app.trikcpns.com/assets/img/logo_main.png" alt="CPNS Logo"
                                    width="150">
                            </a>
                            <div class="main-nav">
                                <ul class="navbar-nav horizontal mr-3 nav-black navbar-style-2">
                                    <li class="nav-item desktop-only">
                                        <a href="#home" class="nav-link">Beranda</a>
                                    </li>
                                    <li class="nav-item desktop-only">
                                        <a href="#feature" class="nav-link">Fitur Unggulan</a>
                                    </li>
                                    <li class="nav-item desktop-only">
                                        <a href="#benefit" class="nav-link">Benefit</a>
                                    </li>
                                    <li class="nav-item desktop-only">
                                        <a href="#price" class="nav-link">Harga</a>
                                    </li>
                                    <li class="nav-item desktop-only">
                                        <a href="#testimonials" class="nav-link">Testimoni</a>
                                    </li>
                                </ul>
                                <a target="blank" :href="'https://www.autoproctor.co/tests/start-test/89JflO8CKz'" class="btn btn-outline-primary mr-3 mr-md-0">Tes Diri Anda</a>&nbsp;
                                <a :href="baseUrl + ''" class="btn btn-outline-primary ml-3 mr-3 mr-md-0">Login</a>&nbsp;

                                <div class="dropdown mobile-only">
                                    <button type="button" class="btn btn-white px-3" id="navbarDropdown"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ti-menu"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right shadow"
                                        aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="#home">Beranda</a>
                                        <a href="https://www.autoproctor.co/tests/start-test/89JflO8CKz" target="blank" class="dropdown-item">Tes Diri Anda</a>
                                        <a class="dropdown-item" href="#feature">Fitur Unggulan</a>
                                        <a class="dropdown-item" href="#benefit">Benefit</a>
                                        <a class="dropdown-item" href="#price">Harga</a>
                                        <a class="dropdown-item" href="#testimonials">Testimoni</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section class="erp_banner_area_two" id="home">
                <div class="home_bubble">
                    <div class="bubble b_one"></div>
                    <div class="bubble b_two"></div>
                    <div class="bubble b_three"></div>
                    <div class="bubble b_four"></div>
                    <div class="bubble b_five"></div>
                    <div class="bubble b_six"></div>
                    <div class="triangle b_seven" data-parallax='{"x": 20, "y": 150}'><img
                            src="img/seo/triangle_one.png" alt=""></div>
                    <div class="triangle b_eight" data-parallax='{"x": 120, "y": -10}'><img
                            src="img/seo/triangle_two.png" alt=""></div>
                    <div class="triangle b_nine"><img src="img/seo/triangle_three.png" alt=""></div>
                </div>

                <div class="erp_shap"></div>
                <div class="erp_shap_two"
                    style="background: url(./img/erp-home/banner_shap.png) no-repeat scroll top left;"></div>
                <div class="section_intro">
                    <div class="section_container">
                        <div class="intro">
                            <div class=" intro_content">
                                <h1>Ayo raih formasi terbaikmu bersama <span style="color:#d12323">trikcpns.com</span>
                                </h1>
                                <p>
                                    Dirancang oleh praktisi dan ahli,<span
                                        class="trikcpns_color"><b>trikcpns.com</b></span>menawarkan platform persiapan
                                    tes
                                    CPNS termutakhir (ter-up-to-date) dan <b><u>berkualitas tinggi</u></b>. Metode
                                    pembelajaran
                                    semi-interaktif juga memastikan kepuasan dan kenyamanan Anda dalam belajar.<span
                                        class="trikcpns_color"><b>trikcpns.com</b></span>
                                    menyediakan soal-soal HOTS, pembahasan, statistik progres belajar, tips dan trik,
                                    tryout
                                    bersama, dan lain-lain. Tunggu apa lagi, pastikan diri Anda lulus tes CPNS dengan
                                    persiapan terbaik bersama<span class="trikcpns_color"><b>trikcpns.com</b></span>
                                </p>
                                <a :href="baseUrl + 'register?package=free'" class="er_btn er_btn_two">Daftar Gratis!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="erp_features_area_two sec_pad" id="feature">
                <div class="container">
                    <div class="row erp_item_features align-items-center flex-row-reverse">
                        <div class="col-lg-4">
                            <div class="erp_features_img_two">
                                <div class="img_icon green"><span class="pluse_1"></span><span class="pluse_2"></span><i
                                        class="icon_lightbulb_alt"></i></div>
                                <img src="https://droitthemes.com/wp/saasland-theme/wp-content/uploads/2019/04/featured_01.png"
                                    alt="">
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="erp_content_two">
                                <div class="hosting_title erp_title">
                                    <h2>Fitur Unggulan<br /><span
                                            style="color:#d12323 !important"><b>#TrikCPNS</b></span>
                                    </h2>
                                </div>
                                <div class="row col-md-12">
                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_pencil-edit"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Soal-soal HOTS dan terbaru</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_document_alt"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Materi yang komperehensif</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_clock_alt"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Waktu belajar yang fleksibel</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_book_alt"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Tersedia ebook untuk latihan</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_laptop"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Tampilan yang bersahabat</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_info_alt"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Informasi Pendaftaran CPNS</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_documents_alt"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Tips & trik yang ampuh</h5>
                                        </div>
                                    </div>

                                    <div class="media erp_item col-md-6 col-sm-12">
                                        <div class="icon green">
                                            <i class="icon_check_alt2"></i>
                                        </div>
                                        <div class="media-body">
                                            <h5>Evaluasi realtime</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="service_promo_area service_promo_area_two" id="benefit">
                <div class="shape_top">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="shape shape_one">
                        <defs>
                            <linearGradient>
                                <stop offset="0%" stop-color="rgb(76,1,124)" stop-opacity="0.95" />
                                <stop offset="100%" stop-color="rgb(103,84,226)" stop-opacity="0.95" />
                            </linearGradient>
                        </defs>
                        <path
                            d="M121.891,264.576 L818.042,11.198 C914.160,-23.786 1020.439,25.773 1055.424,121.890 L1308.802,818.041 C1343.786,914.159 1294.227,1020.439 1198.109,1055.422 L501.958,1308.801 C405.840,1343.785 299.560,1294.226 264.576,1198.108 L11.198,501.957 C-23.786,405.839 25.773,299.560 121.891,264.576 Z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="shape shape_two">
                        <defs>
                            <linearGradient id="PSgrad_0">
                                <stop offset="0%" stop-color="rgb(76,1,124)" stop-opacity="0.95" />
                                <stop offset="100%" stop-color="rgb(103,84,226)" stop-opacity="0.95" />
                            </linearGradient>

                        </defs>
                        <path fill="url(#PSgrad_0)"
                            d="M121.891,264.575 L818.042,11.198 C914.160,-23.786 1020.439,25.772 1055.424,121.890 L1308.802,818.040 C1343.786,914.159 1294.227,1020.439 1198.109,1055.423 L501.958,1308.801 C405.840,1343.785 299.560,1294.226 264.576,1198.107 L11.198,501.957 C-23.786,405.839 25.773,299.560 121.891,264.575 Z" />
                    </svg>
                </div>
                <div class="s_service_section">
                    <div class="container">
                        <h2 class="f_p f_size_40 l_height50 f_600 t_color text-center wow fadeInUp hosting_title"
                            data-wow-delay="0.3s">
                            <span style="color:#d12323" class="f_1000"><b>Benefit</b></span> Lainnya<br />
                            <p>Selain fitur unggulan yang kami tawarkan, Anda juga akan memperoleh benefit lainnya!</p>
                        </h2>
                        <div class="row s_service_info mt_70">
                            <div class="col-lg-4 col-sm-6">
                                <div class="s_service_item wow fadeInLeft" data-wow-delay="0.3s">
                                    <div class="solid_overlay"></div>
                                    <div class="icon icon_1">
                                        <i class="ti-light-bulb"></i>
                                    </div>
                                    <h5 class="f_p f_size_20 f_600 t_color">Tips & Trik</h5>
                                    <p class="l_height28">Tips dan Trik yang hanya bisa didapat oleh pengguna, tips dan
                                        trik
                                        ini berisi manajemen waktu, cara-cara cepat, metode belajar dan pengerjaan soal
                                        ketika test untuk mendapatkan skor 400, bahkan tips untuk memilih jabatan CPNS
                                        yang
                                        diinginkan.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="s_service_item wow fadeInLeft" data-wow-delay="0.5s">
                                    <div class="solid_overlay"></div>
                                    <div class="icon icon_2">
                                        <i class="ti-time"></i>
                                    </div>
                                    <h5 class="f_p f_size_20 f_600 t_color">Manajemen Waktu</h5>
                                    <p class="l_height28">Belajar manajemen waktu saat mengerjakan soal.</p>

                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="s_service_item wow fadeInLeft" data-wow-delay="0.7s">
                                    <div class="solid_overlay"></div>
                                    <div class="icon icon_3">
                                        <i class="ti-book"></i>
                                    </div>
                                    <h5 class="f_p f_size_20 f_600 t_color">Modul & Kisi-kisi Lengkap</h5>
                                    <p class="l_height28">Kami menghadirkan modul dan kisi-kisi lengkap SKD (TWK, TIU, &
                                        TKP) yang dapat diakses kapanpun.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pricing_area_three sec_pad_price" id="price">
                <div class="container custom_container p0">
                    <div class="sec_title text-center mb_70">
                        <h2 class="f_p f_size_40 l_height50 f_700 trikcpns_color"><span>Harga Paket</span></h2>
                    </div>
                    <div class="price_content price_content_three">
                        <div class="fade show active">
                            <div class="row">
                                <div class="col-lg-3 col-sm-6" v-for="item in list_package" v-bind:key="item.id">
                                    <div class="price_item">
                                        <h5 class="f_p f_size_30 f_600 t_color2 mt_30">{{item.code}}</h5>
                                        <div class="price f_700 f_size_30 t_color2">
                                            <span v-if="item.code != 'free'" class="f_500 f_size_20" style="text-decoration: line-through rgb(0, 0, 0); color: rgb(214, 214, 214) !important;">{{item.text_price_out}}<br/></span>
                                            {{item.text_price}}
                                            <sub class="f_size_16 f_400">/ tahun</sub>
                                        </div>
                                        <div v-html="item.description_other"></div>
                                        <a :href="baseUrl + 'register?package='+item.code" class="price_btn btn_hover">{{item.code != 'free' ? 'Daftar':'Coba gratis'}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="app_testimonial_area" id="testimonials">
                <div class="sec_title text-center mb_70" style="margin-top:-90px">
                    <h2 class="f_p f_size_40 l_height50 f_700"><span class="trikcpns_color">Testimoni</span> Pengguna
                    </h2>
                </div>
                <div class="container nav_container">
                    <div class="shap one"></div>
                    <div class="shap two"></div>
                    <carousel :autoplay="true" :nav="false" :items=1 class="app_testimonial_slider">
                        <div class="app_testimonial_item text-center">
                            <div class="author_info">
                                <h6 class="f_p f_500 f_size_18 t_color3 mb-20">Ananda, S.Si., M.Si.</h6>
                                <p>CPNS 2019 Kemendikbud
                                    <br />
                                    Dosen - Asisten Ahli
                                    Fakultas Peternakan
                                    Universitas Andalas, Padang.
                                </p>
                            </div>
                            <p class="f_400">
                                Saya sangat merekomendasikan website belajar trikcpns.com ke semua calon pendaftar CPNS
                                khususnya yang memiliki kesibukan atau tidak banyak memiliki waktu luang untuk belajar
                                karena dengan trikcpns.com yang sangat user-friendly ini akan sangat memudahkan proses
                                belajar dengan berbagai fitur pendukung di dalamnya. Seluruh materi sudah tersedia, kita
                                hanya perlu "kelincahan" untuk mampu mempelajari setiap materi dengan cepat dan tepat.
                                Materi yang disediakan lengkap, soal-soal sesuai dengan peraturan terbaru, statistik
                                hasil nilai yang detail dan yang tidak kalah penting dapat diakses dimana saja serta
                                selalu update baik materi, maupun soal2nya. Nilai investasi yang sangat murah untuk
                                segala manfaat yang diberikan
                            </p>
                        </div>

                        <div class="app_testimonial_item text-center">
                            <div class="author_info">
                                <h6 class="f_p f_500 f_size_18 t_color3 mb-20">M.Rizqi Hidayat,S.Pd.I, M.Pd</h6>
                                <p>CPNS 2019 Kab.Lombok Timur<br />Guru kelas
                                    SDN 3 Pringgajurang Kec.Montong Gading</p>
                            </div>
                            <p class="f_400">
                                Terima kasih trikcpns.com. berkat website terpercaya ini, Alhamdulillah saya lolos dan
                                diterima di CPNS tahun 2019. soalnya luar biasa nyaris 70 persen masuk semua di soal
                                cpns kemarin. website trikcpns.com top markotop deh
                            </p>
                        </div>

                        <div class="app_testimonial_item text-center">
                            <div class="author_info">
                                <h6 class="f_p f_500 f_size_18 t_color3 mb-20">Fajar Efendi Daulay, S.Pd., M.Pd.</h6>
                                <p>PNS Formasi Guru SMK Medan, Sumatera Utara</p>
                            </div>
                            <p class="f_400">ASN merupakan sebuah profesi yang paling bnyak di minati pada masa ini,.
                                Tentunya persaingan untuk merebutkan sebuah kursi di Pemerintahan sangat Berat, dengan
                                TrikCPNS.com semua akan terasa mudah untuk lolos menjadi CPNS, karena di lengkapi dengan
                                soal-soal HoTS yang uptodate serta trik-trik yang luar biasa dan terbukti sudah
                                meloloskan
                                banyak CPNS di kementrian dan pemerimtah daerah.</p>
                        </div>

                        <div class="app_testimonial_item text-center">
                            <div class="author_info">
                                <h6 class="f_p f_500 f_size_18 t_color3 mb-20">Riya Aditiya</h6>
                                <p>PNS Formasi PNS BATAN</p>
                            </div>
                            <p class="f_400">TrikCPNS memberikan pengajaran dalam persiapan menghadapi tes seleksi CPNS.
                                Kita juga memperoleh berbagai informasi dan pengetahuan terkait soal CPNS nantinya.
                                Selain
                                itu dengan ada nya tru out membantu kita sehingga kita lebih siap dalam mengerjakan soal
                                soal pada tes CPNS seperti TWK, TIU, TKP</p>
                        </div>
                    </carousel>
                </div>
            </section>

            <footer class="footer_area footer_area_four f_bg">
                <div class="footer_bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <p class="mb-0 f_100">Copyright © 2020 <a style="color:#d12323 !important"
                                        href="https://trikcpns.com/"><b>trikcpns.com</b></a>. All Rights Reserved.</p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                                <ul class="list-unstyled f_menu">
                                    <li>Dibuat dengan ❤️ oleh <a style="color:#d12323 !important"
                                            href="https://trikcpns.com/"><b>trikcpns.com</b></a></li>
                                    <li>
                                        <router-link to="privacy-policy">Kebijakan Privasi</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    </div>
</template>

<script>
    import carousel from 'vue-owl-carousel'
    // @ is an alias to /src
    export default {
        name: 'Home',
        components: {
            carousel
        },
        data() {
            return {
                baseUrl : process.env.VUE_APP_APIHOST ,
                list_package : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.$http.get(this.baseUrl + 'api/web/package').then(res => {
                    let getResponse = res.data;
                    this.list_package = getResponse;
                });
            }
        }
    }
</script>